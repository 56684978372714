<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header">
                <div class="card-title">
                    <p class="card-label font-weight-normal">
                        {{ driver.first_name }} {{ driver.last_name }} 
                        (<span class="font-weight-bold">{{ driver.unique_id }}</span>)
                    </p>
                </div>
                <div class="card-toolbar">
                    <a class="a" v-b-modal.update-driver-modal><i class="flaticon2-edit text-primary"></i> Edit Profile</a>
                </div>
            </div>
        </div>
    <!-- Update Modal -->
    <b-modal
      id="update-driver-modal"
      ref="modal"
      @ok="updateDriver"
      title="Edit Profile"
      size="lg"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-3">
            <b-form-group label="Account">
              <b-form-input placeholder="Account" v-model="form.unique_id"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Title">
                <b-form-input placeholder="Title" v-model="form.title"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="First name">
              <b-form-input placeholder="First name" v-model="form.first_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Last name">
              <b-form-input placeholder="Last name" v-model="form.last_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Email">
              <b-form-input placeholder="name@domain.com" v-model="form.email"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Phone Number">
              <b-form-input placeholder="+91 888 470 8921" v-model="form.phone"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Alternative Number">
              <b-form-input placeholder="Alternative phone" v-model="form.alt_phone"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Identity Type">
              <b-form-input placeholder="Identity Type" v-model="form.identity_type"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Identity Number">
              <b-form-input placeholder="Identity Number" v-model="form.identity_number"></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <loading :active.sync="isLoading"></loading>
    </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      driver: {},
      isLoading: false,
      id: this.$route.params.id, 
      errors: [],
      form: {
        unique_id: null,
        title: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        identity_number: null,
        identity_type: null,
        alt_phone: null,
        id: null,
      },
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getDriver();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Drivers" },
    ]);
  },
  methods: {
    checkErrors() {
      if (this.form.unique_id && this.form.first_name && this.form.last_name && this.form.email && this.form.phone
      ) {
        return true;
      }
      this.errors = [];
      if (!this.form.unique_id) {
        this.errors.push("Account is required.");
      }
      if (!this.form.first_name) {
        this.errors.push("First Name is required.");
      }
      if (!this.form.last_name) {
        this.errors.push("Last Name is required.");
      }
      if (!this.form.email) {
        this.errors.push("Email is required.");
      }
      if (!this.form.phone) {
        this.errors.push("Phone is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    updateDriver(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.update("updateDriver", this.id, this.form)
          .then(() => {
            this.$nextTick(() => {
              this.$bvModal.hide("update-driver-modal");
              this.makeToastVariant(
                "success",
                "Profile Updated successfully",
                "Success"
              );
              this.getDriver();
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    getDriver() {
      this.isLoading = true;
      ApiService.get("getDriver", this.id)
        .then(({ data }) => {
          this.driver = data.driver;
          this.form = {...data.driver};
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>